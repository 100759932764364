export const getSuitesData = () => {
  return [
    {
      carouselImages: [
        "Suites/Carousel/suites_gallery_0.jpg",
        "Suites/Carousel/suites_gallery_1.jpg",
        "Suites/Carousel/suites_gallery_2.jpg",
        "Suites/Carousel/suites_gallery_3.jpg",
        "Suites/Carousel/suites_gallery_4.jpg",
        "Suites/Carousel/suites_gallery_5.jpg",
        "Suites/Carousel/suites_gallery_6.jpg",
        "Suites/Carousel/suites_gallery_7.jpg",
      ],
      title: "Luxury Suites",
      // subTitle: '',
      bottomLinks: [
        {
          title: "gallery",
          link: "modal",
          icon: "gallery",
        },
        // {
        //     title: 'appointment',
        //     link: 'appointment',
        //     icon: 'appointment'
        // }
      ],
    },
  ];
};
