<template>
  <div id="Suites">
    <!--<TertiaryNav v-if="purl === 'admin.0000' || purl === ''" :tertiaryNavData="tertiaryNavData" />-->
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <div class="gradient"></div>
    <TicketPackages
      :ticketPackagesHeader="ticketPackagesHeader"
      :packages="packages"
      :ticketPackageImages="ticketPackageImages"
    />
  </div>
</template>

<script>
import TertiaryNav from "@/components/Layout/TopNav/TertiaryNav";
import HeroCarousel from "@/components/patterns/HeroCarousel";
import TicketPackages from "@/components/patterns/TicketPackages";
import { getTicketPackagesHeader } from "@/data/TicketPackages/suitesTicketPackagesData";
import { getSuitesData } from "@/data/HeroCarousel/suitesCarousel";
import axios from "axios";

export default {
  name: "Suites",
  components: {
    TertiaryNav,
    HeroCarousel,
    TicketPackages,
  },
  data() {
    return {
      heroCarouselData: getSuitesData(),
      // ticketPackagesHeader: getTicketPackagesHeader(),
      ticketPackagesHeader: {
        header:
          "<p>A spacious and private luxury suite delivers prime views and unparalleled comfort to entertain clients or indulge with friends.</p>",
      },
      packages: null,
      ticketPackageImages: [
        "Suites/suites_the-details.jpg",
        "Suites/suites_the-cuisine-the-drinks.jpg",
        "Suites/suites_the-view.jpg",
      ],
      tertiaryNavData: {
        text: "<b>HOLIDAY SALE!</b> For a limited time, save up to 30% on Labor Day weekend suites. Offer valid until 12/31/20. Call (718) 760-6236 for details.",
        phone: "(718) 760-6236",
      },
      purl: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    this.purl = this.$store.state.epitch.purl;

    var axel = Math.random() + "";
    var a = axel * 10000000000000;
    var axel2 = Math.random() + "";
    var b = axel2 * 10000000000000;

    let floodlightTag = document.createElement("script");
    let floodlightTag2 = document.createElement("script");
    floodlightTag.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
        a +
        "?"
    );
    floodlightTag2.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=luxur001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
        b +
        "?"
    );
    document.body.appendChild(floodlightTag);
    document.body.appendChild(floodlightTag2);

    let pages = this.$store.state.epitch.ePitch.pages;
    pages.forEach((page) => {
      if (page.id === "4") {
        this.packages = JSON.parse(page.copy);
      }
    });

    if (this.$store.state.epitch.ePitch.mode === "live") {
      const purl = this.$store.state.epitch.ePitch.purl;
      const epitchUrl = this.$store.state.epitch.url;
      axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
        method: "page",
        purl: purl,
        presentationId: window.presentationId,
        sessionDbId: window.sessionDbId,
        section: 4,
      });
    }

    let copy = this.$store.state.epitch.ePitch.pages;
    if (typeof copy !== "undefined") {
      copy.forEach((page) => {
        if (page.id === "4") {
          let description = JSON.parse(page.copy);
          let pageDescription = description.pageDescription;
          if (pageDescription) {
            this.ticketPackagesHeader.header = pageDescription;
          }
          // window.console.log('description', pageDescription)
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// #Suites {
//   margin-top: 136px;
//   @media (max-width: $lg) {
//     margin-top: 85px;
//   }
//   @media (max-width: 757px) {
//     margin-top: 97px;
//   }
//   @media (max-width: 385px) {
//     margin-top: 110px;
//   }
// }
</style>